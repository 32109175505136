// .final-settlement-element-tabs {
//   button {
//     padding: 10px 30px;
//     cursor: pointer;
//     opacity: 0.6;
//     background: white !important;
//     background-color: white !important;
//     color: #000 !important;
//     border: 0;
//     outline: 0;
//     border-bottom: 2px solid transparent;
//     transition: ease border-bottom 250ms;
//     border-radius: 0px !important;

//     &.active {
//       border-bottom: 0px solid black !important;
//       border-top: 1px solid black !important;
//       border-right: 1px solid black !important;
//       border-left: 1px solid black !important;
//       background: white !important;
//       background-color: white !important;
//       color: #000 !important;
//       opacity: 1;
//     }
//     :active{
//       border-bottom: 2px solid black !important;
//       background: white !important;
//       background-color: white !important;
//       color: #000 !important;
//       opacity: 1;
//     }
//     :focus{
//       border-bottom: 2px solid black !important;
//       background: white !important;
//       background-color: white !important;
//       color: #000 !important;
//       opacity: 1;
//     }
//     :hover{
//       border-bottom: 2px solid black !important;
//       background: white !important;
//       background-color: white !important;
//       color: #000 !important;
//       opacity: 1;
//     }
//   }
// }

.final-settlement-element-tabs {
  display: flex;
  position: relative;

  .btn.btn-light.active,
  .btn.btn-light.active:hover,
  button {
    border: 1px solid transparent;
    padding: 20px;
    font-size: 14px;
    background-color: #fff !important;
  }

  button.active {
    border: 1px solid black;
    border-radius: 5px 5px 0 0;
    border-bottom: 1px solid white;
    z-index: 2;
    border-top: 1px solid #000 !important;
    border-right: 1px solid #000 !important;
    border-left: 1px solid #000 !important;
  }
}
.final-settlement-element-tabs::after {
  content: "";
  width: 100%;
  border-bottom: 1px solid black;
  position: absolute;
  bottom: 0;
}
.chatter-message-container{
  margin-bottom: 0.2rem;
  padding-bottom: 0px;
  p{
    margin-bottom: 0.2rem;
    padding-bottom: 0px;
  }
}

.chatter-attahment-container {
  .form-label{
    font-size: 1rem;
  }
  i{
    float: right;
  }
}
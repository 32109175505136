.salary-structure h4{
  position:relative;
  text-align: center;
  background: linear-gradient(0deg,hsl(0deg 0% 100% / 15%) 25%,white 0,transparent 44%,hsl(0deg 0% 0%) 0,hsl(0deg 0% 0%) 55%,transparent 0,transparent);
  background-clip: content-box;
}
// .salary-structure h4:before {
//   content: "";
//   display: block;
//   border-top: solid 2px #535353;
//   width: 100%;
//   height: 2px;
//   position: absolute;
//   top: 50%;
//   z-index: 0;
// }
.salary-structure h4 span {
  background: #fff;
  padding: 0 10px;
  position: relative;
}
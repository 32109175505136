.azk-status-bar {
  display: flex;
  align-items: center;
  padding-right: 15px;
  /* background-color: #bcbcbc; */
  height: 32px;
  margin: 20px;
}

.azk-status {
  flex: 1;
  padding: 6px 0;
  text-align: center;
  position: relative;
}

.azk-status-bar div:first-child:before {
  border-left: 0px;
}

.azk-status-bar div:last-child {
  width: 36%;
}

.azk-status-bar {
  --bs-badge-border-radius: 0.425rem 0.425rem 0.3rem 0.3rem;
  .azk-status:before {
    content: "";
    border-top: 15px solid transparent;
    border-bottom: 15px solid transparent;
    position: absolute;
    top: 0;
    left: -25px;
  }
  .azk-status:after {
    content: "";
    border-top: 15px solid transparent;
    border-bottom: 15px solid transparent;
    position: absolute;
    top: 0;
    right: -16px;
    z-index: 20;
  }
  .badge-danger:after {
    color: var(--bs-danger);
    border-left: 20px solid var(--bs-danger);
  }
  .badge-light-danger:after {
    color: var(--bs-danger-light);
    border-left: 20px solid var(--bs-danger-light);
  }

  
  .badge-warning:after {
    color: var(--bs-warning);
    border-left: 20px solid var(--bs-warning);
  }
  .badge-light-warning:after {
    color: var(--bs-warning-light);
    border-left: 20px solid var(--bs-warning-light);
  }
  
  .badge-success:after {
    color: var(--bs-success);
    border-left: 20px solid var(--bs-success);
  }
  .badge-light-success:after {
    color: var(--bs-success-light);
    border-left: 20px solid var(--bs-success-light);
  }
  
  .badge-primary:after {
    color: var(--bs-primary);
    border-left: 20px solid var(--bs-primary);
  }
  .badge-light-primary:after {
    color: var(--bs-primary-light);
    border-left: 20px solid var(--bs-primary-light);
  }
}